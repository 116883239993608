@import "theme/theme";

* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow: auto;
}

body {
  margin: 0;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: $color-black;
  background-color: $color-black-ultra-light;
  overflow: auto;
}

#root {
  height: 100%;
  // min-height: 700px;
  // min-width: 1300px;
}

@media print {
  body {
    height: auto;
    background: none;
  }

  nav {
    display: none !important;
  }

  #root {
    min-width: 0;
  }

  #hubspot-messages-iframe-container {
    // hide hubspot's chatbot
    visibility: hidden !important;
  }

  .MuiPaper-root {
    display: none !important;
  }

  .MuiTooltip-popper {
    display: none !important;
  }
}

#outdated{
  text-align: center;
  position: absolute;
  width: 100vw;
}

.NotificationContainer {
  margin: 8px 0;
  pointer-events: none
}
