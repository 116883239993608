$color-primary: #236fff;
$color-primary-medium: #78a7fe;
$color-primary-light: #e0ebff;
$color-primary-dark: #034acf;

$color-secondary: #ffc107;

$color-black: #082b2b;
$color-black-medium: #849191;
$color-black-light: #bac1c1;
$color-black-disabled: #e2e5e5;
$color-black-ultra-light: #f6f6f6;
$color-black-loading-shine: #d8d8d8;

$color-white: #ffffff;
$color-danger: #b00020;
$color-danger-light: #f8e5e8;
$color-green: #43a445;
$color-success: #25A52A;
$color-warning: #FB8C00;

$color_icon_dark: rgba(0, 0, 0, 0.54);
$color_icon_dark_inactive: rgba(0, 0, 0, 0.26);
$color_icon_light: rgba(255, 255, 255, 1);
$color-icon-light-inactive: rgba(255, 255, 255, 0.3);

$color-card-hover: #e0f1ff;
